import { SimpleCard, FormButton } from "stj-components";
import { ConvocatoriaAddOrUpdateInterface, ConvocatoriaInterface } from "../../interfaces/ConvocatoriaInterface";
import { Dialog, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useUpdateConvocatoria } from "../../services/api/convocatorias";


export const EditConvocatoriaValidationSchema = Yup.object({
    descripcion: Yup.string().required("Se necesita una descripcion de la convocatoria"),
    expediente: Yup.string().nullable(),
    observacion: Yup.string().nullable(),
    vigente: Yup.boolean(),
});


interface ModalEditConvocatoriaProps {
    show: boolean,
    handleClose: () => void;
    convocatoria: ConvocatoriaInterface;
    refresh: any;
}

export const ModalEditConvocatoria = (props: ModalEditConvocatoriaProps) => {
    const { show, handleClose, convocatoria, refresh } = props;

    const editConvocatoria = useUpdateConvocatoria(convocatoria.id);

    const initialValues: ConvocatoriaAddOrUpdateInterface = {
        descripcion: convocatoria.descripcion,
        expediente: convocatoria.expediente,
        observacion: convocatoria.observacion,
        vigente: convocatoria.vigente,
    };


    const handleOnSubmit = (convocatoria: ConvocatoriaAddOrUpdateInterface) => {
        console.log({ convocatoria });
        editConvocatoria.mutate(convocatoria, {
            onSuccess: () => {
                refresh();
                handleClose();
            }
        });
    };

    return (
        <Dialog open={show} fullWidth maxWidth="md">
            <SimpleCard title="Editar convocatoria">
                <Formik
                    initialValues={initialValues}
                    validationSchema={EditConvocatoriaValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleOnSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid container item spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Nombre de la convocatoria"
                                        id="descripcion"
                                        value={formik.values.descripcion ?? ""}
                                        onChange={(event) => formik.setFieldValue("descripcion", event.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Expediente"
                                        id="expediente"
                                        value={formik.values.expediente ?? ""}
                                        onChange={(event) => formik.setFieldValue("expediente", event.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Observaciones"
                                        id="observacion"
                                        value={formik.values.observacion ?? ""}
                                        onChange={(event) => formik.setFieldValue("observacion", event.target.value)}
                                        fullWidth
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="estadoInputLabel">Estado</InputLabel>
                                        <Select
                                            labelId="estadoInputLabel"
                                            id="estadoInput"
                                            value={formik.values.vigente}
                                            label="Estado"
                                            onChange={(event) => formik.setFieldValue("vigente", event.target.value)}
                                        >
                                            <MenuItem value={0}>ARCHIVADA</MenuItem>
                                            <MenuItem value={1}>VIGENTE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12} md={4} alignItems="right">
                                        <FormButton
                                            text="Cancelar"
                                            type="button"
                                            variant="outlined"
                                            onClick={() => handleClose()}
                                            color="secondary"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <FormButton
                                            text="Guardar"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </SimpleCard>
        </Dialog>
    );
}
