import { Alert, AlertTitle, Divider, Grid, Button, CircularProgress } from "@mui/material";
import { apiRoutes } from "../../../services/api/apiRoutes";
import { useFileDownload } from "../../../services/api/canvas-integration";
import { FormEstadisticas } from "./FormEstadisticas";
import { ChartsEstadisticas } from "./ChartsEstadisticas";
import { useState } from "react";


interface EstadisticasPageProps {
  convocatoriaId: string;
  convocatoriaSlug: string;
}

const EstadisticasPage = (
  props: EstadisticasPageProps
) => {
  const { convocatoriaId, convocatoriaSlug } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [estadisticas, setEstadisticas] = useState<any>(null);

  const fileDownload = useFileDownload(true);

  const handleDownloadCSVCompleto = () => {
    const fileName = `CSV postulantes confirmados - ${convocatoriaSlug}.csv`;

    const formValues = {
      convocatoria_id: convocatoriaId,
    };

    fileDownload.mutateAsync({
      fileName: fileName,
      formValues: formValues,
      url: apiRoutes.canvasIntegrationCsvCompleto,
    });
  }

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Button
          onClick={handleDownloadCSVCompleto}
          variant="contained"
          fullWidth
          disabled={fileDownload.isLoading}
        >
          {fileDownload.isLoading
            ? (<CircularProgress />)
            : ("Descargar CSV para trabajar con EXCEL")
          }
        </Button>
      </Grid>

      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            En esta pestaña se muestran estadísticas resumidas sobre la convocatoria.
          </AlertTitle>
        </Alert>
      </Grid>


      <Grid container item xs={12} spacing={2}>
        {/* Formulario */}
        <FormEstadisticas convocatoriaId={convocatoriaId} setLoading={setLoading} setEstadisticas={setEstadisticas} />

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        {/* Gráficos */}
        <ChartsEstadisticas loading={loading} estadisticas={estadisticas} />
      </Grid >
    </>
  );
};

export default EstadisticasPage;
