import { useMemo } from 'react';
import { CircularProgress, Grid, TextField } from '@mui/material';
import { SelectInput, TextInput, SingleAutocomplete } from 'stj-components';
import { useGetActividades } from '../../../../services/api/actividades';
import { useGetConvocatorias } from '../../../../services/api/convocatorias';
import { ConvocatoriaInterface } from '../../../../interfaces/ConvocatoriaInterface';

export const FormConvocatoriaCalendario = () => {

  const { data: actividades, isLoading } = useGetActividades();

  const datos = useMemo(
    () => ({
      actividades: actividades?.map((x) => ({
        label: x.descripcion,
        value: x.id,
      }))
    }),
    [actividades]
  );

  return (
    <Grid container item xs={12} spacing={2} justifyContent="right">
      {isLoading && (<Grid item xs={12}><CircularProgress /></Grid>)}
      {datos.actividades && (
        <Grid item xs={12}>
          <SelectInput
            autoFocus={true}
            label="Actividades"
            options={datos.actividades}
            name="actividad_id"
            defaultValue=""
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <TextInput
          label="Fecha Inicio"
          name="fecha_inicio"
          type="date"
          placeholder="Ingrese una fecha de inicio"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Fecha Fin"
          name="fecha_fin"
          type="date"
          placeholder="Ingrese una fecha de fin"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          autoFocus={true}
          label="Observaciones"
          name="observacion"
          type="text"
          placeholder="Ingrese la observaciones"
          fullWidth
          multiline={true}
        />
      </Grid>
    </Grid>
  )
};