import { pathToUrl } from "../../utils/urlGenerator";
import {
  PostulanteInterface,
  PaginatedPostulantesInterface,
} from "../../interfaces/PostulanteInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { usePost, useFetch } = useReactQuery;

export const useGetEstadisticas = () =>
  usePost(pathToUrl(apiRoutes.estadisticas));

// TODO: falta normalizar las localidades
// Estos endpoints son de atributos no normalizados
export const useGetLocalidades = (queryParams?: any) =>
  useFetch<string[]>(pathToUrl(apiRoutes.estadisticasLocalidades), queryParams);

export const useGetNacionalidades = (queryParams?: any) =>
  useFetch<string[]>(
    pathToUrl(apiRoutes.estadisticasNacionalidades),
    queryParams
  );
