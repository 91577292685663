import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useEnviarCredencialesHain,
  useGenerarUsuarios,
  useToggleActivoUsuarios,
} from "../../../services/api/convocatorias";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useGetPerfiles } from "../../../services/api/perfiles";

interface GestionUsuariosPageProps {
  convocatoriaId: string;
}

const GestionUsuariosPage = (props: GestionUsuariosPageProps) => {
  const { convocatoriaId } = props;
  const [perfilId, setPerfilId] = useState<string>("3");

  const generarUsuariosMutation = useGenerarUsuarios({
    id: convocatoriaId,
    perfil_id: perfilId,
  });
  const toggleActivoUsuariosMutation = useToggleActivoUsuarios({
    id: convocatoriaId,
    perfil_id: perfilId,
  });
  const enviarCredencialesHainMutation = useEnviarCredencialesHain({
    id: convocatoriaId,
    perfil_id: perfilId,
  });
  const [enviarIsLoading, setEnviarIsLoading] = useState<boolean>(false);

  const { data: perfiles, isLoading: isLoadingPerfiles } = useGetPerfiles({});

  const perfilesSelect = useMemo(() => {
    return perfiles?.filter((p) => p.id !== 1);
  }, [perfiles]);

  const handleChange = (event: SelectChangeEvent) => {
    setPerfilId(event.target.value as string);
  };

  const handleGenerarUsuarios = async () => {
    setEnviarIsLoading(true);
    const data = {};
    try {
      await generarUsuariosMutation.mutateAsync(data, {
        onSuccess: () => {
          toast.success("Se generaron los usuarios.");
        },
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    } finally {
      setEnviarIsLoading(false);
    }
  };

  const handleToggleActivoUsuarios = async () => {
    setEnviarIsLoading(true);
    const data = {};
    try {
      await toggleActivoUsuariosMutation.mutateAsync(data, {
        onSuccess: () => {
          toast.success("Se actualizaron los usuarios.");
        },
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    } finally {
      setEnviarIsLoading(false);
    }
  };

  const handleEnviarCredencialesHain = async () => {
    setEnviarIsLoading(true);
    const data = {};
    try {
      await enviarCredencialesHainMutation.mutateAsync(data, {
        onSuccess: () => {
          toast.success(
            "Se comenzó el envío de credenciales. Este proceso puede demorar"
          );
        },
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    } finally {
      setEnviarIsLoading(false);
    }
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {!isLoadingPerfiles ? (
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel id="select-eleccion">Perfil</InputLabel>
            <Select
              labelId="select-eleccion"
              id="select-eleccion"
              value={perfilId}
              label="Perfil"
              onChange={handleChange}
            >
              {perfilesSelect?.map((perfil, idx) => (
                <MenuItem key={idx} value={String(perfil.id)}>
                  {`${perfil.descripcion} (${perfil.label})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <CircularProgress />
      )}

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            Los siguientes botones son para generar usuarios y darles acceso o
            quitárselo para gestionar curriculum.
          </AlertTitle>
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleGenerarUsuarios}
          variant="contained"
          fullWidth
          disabled={enviarIsLoading}
        >
          {enviarIsLoading ? (
            <CircularProgress />
          ) : (
            "Generar usuarios para postulantes"
          )}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleToggleActivoUsuarios}
          variant="contained"
          fullWidth
          disabled={enviarIsLoading}
        >
          {enviarIsLoading ? (
            <CircularProgress />
          ) : (
            "Activar/inactivar usuarios"
          )}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleEnviarCredencialesHain}
          variant="contained"
          fullWidth
          disabled={enviarIsLoading}
        >
          {enviarIsLoading ? (
            <CircularProgress />
          ) : (
            "Enviar mail de credenciales"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default GestionUsuariosPage;
