/**
 * Soporta las siguiente posibilidades:
 * "file.name.with.dots.txt"
 * "file.txt"
 * "file"
 * ""
 * null
 * undefined
 * @returns extensión
 */
export const getFileExtension = (file: any) => {
  const re = /(?:\.([^.]+))?$/;

  const regResult = re.exec(file);

  const extension = regResult![1];

  return extension;
};

export const formatDate = (date: Date | string) => {
  return new Date(date).toLocaleString("es-AR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDateAR = (date: Date | string) => {
  const argentinaTimezone = "GMT-3";

  return date
    ? new Date(`${date} ${argentinaTimezone}`).toLocaleString("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    : "";
};

export const formatDateISO = (date: Date | string) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const getTodayRFC3339 = () => {
  let now = new Date();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  let formattedMonth = month < 10 ? "0" + String(month) : String(month);
  let formattedDay = day < 10 ? "0" + String(day) : String(day);
  // if (month < 10) {
  //   formattedMonth = "0" + String(month);
  // }
  // if (day < 10) {
  //   formattedDay = "0" + String(day);
  // }
  let today = now.getFullYear() + "-" + formattedMonth + "-" + formattedDay;

  return today;
};

export const replaceSpecialCharacters = (inputString: string) => {
  // Expresión regular que coincide con cualquier carácter que no sea una letra, un número o un espacio en blanco,
  // y cualquier diacrítico (tilde, diéresis, etc.) que preceda a una letra
  const regex = /[^a-z0-9\s\u00C0-\u024F]/gi;

  // Reemplazo vacío para eliminar caracteres especiales y diacríticos
  const replacement = "";

  // Se utiliza el método `replace` en la cadena de entrada para reemplazar cualquier ocurrencia de los caracteres
  // coincidentes con la expresión regular con un reemplazo vacío
  const outputString = inputString.normalize("NFD").replace(regex, replacement);

  // Devolver la cadena resultante
  return outputString;
};

export const getRFC3339 = (date: Date | string): string => {
  let now = new Date(`${date} ${"GMT-3"}`);
  let month = now.getMonth() + 1;
  let day = now.getDate();
  let formattedMonth = month < 10 ? "0" + String(month) : String(month);
  let formattedDay = day < 10 ? "0" + String(day) : String(day);
  let today = now.getFullYear() + "-" + formattedMonth + "-" + formattedDay;

  return today;
};

const rot47Encode = (data: string) => {
  var s = [];
  for (var i = 0; i < data.length; i++) {
    var j = data.charCodeAt(i);
    if (j >= 33 && j <= 126) {
      s[i] = String.fromCharCode(33 + ((j + 14) % 94));
    } else {
      s[i] = String.fromCharCode(j);
    }
  }
  return s.join("");
};

export const Rot47 = {
  encode: rot47Encode,
};

export const getColors = ({
  cantidad,
  offset = 0,
}: {
  cantidad: number;
  offset?: number;
}): string[] => {
  const colores: string[] = [
    "rgba(60, 167, 93, 0.3)",
    "rgba(61, 64, 91, 0.3)",
    "rgba(1, 211, 178, 0.3)",
    "rgba(214, 180, 140, 0.3)",
    "rgba(206, 128, 79, 0.3)",
    "rgba(75, 161, 144, 0.3)",
    "rgba(255, 151, 169, 0.3)",
    "rgba(183, 159, 255, 0.3)",
    "rgba(225, 116, 81, 0.3)",
    "rgba(1, 167, 247, 0.3)",
    "rgba(241, 107, 148, 0.3)",
    "rgba(249, 227, 170, 0.3)",
    "rgba(140, 153, 87, 0.3)",
    "rgba(218, 210, 101, 0.3)",
    "rgba(174, 142, 97, 0.3)",
  ];

  return colores.slice(offset, cantidad + offset);
};

export const getBorderColors = ({
  cantidad,
  offset = 0,
}: {
  cantidad: number;
  offset?: number;
}): string[] => {
  const colores: string[] = [
    "rgba(60, 167, 93, 1)",
    "rgba(61, 64, 91, 1)",
    "rgba(1, 211, 178, 1)",
    "rgba(214, 180, 140, 1)",
    "rgba(206, 128, 79, 1)",
    "rgba(75, 161, 144, 1)",
    "rgba(255, 151, 169, 1)",
    "rgba(183, 159, 255, 1)",
    "rgba(225, 116, 81, 1)",
    "rgba(1, 167, 247, 1)",
    "rgba(241, 107, 148, 1)",
    "rgba(249, 227, 170, 1)",
    "rgba(140, 153, 87, 1)",
    "rgba(218, 210, 101, 1)",
    "rgba(174, 142, 97, 1)",
  ];

  return colores.slice(offset, cantidad + offset);
};
