import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  GridMenuOption,
  MenuOption,
} from "../../components/crud/GridMenuOption";
import { ContextualButton } from "stj-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Role } from "@interfaces/Role";
import { UserInterface } from "@interfaces/UserInterface";
import { IconButton } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Close";

export const columnsUsers: GridColDef[] = [
  GridMenuOption,
  {
    field: "name",
    headerName: "Nombre & Apellido",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "externo",
    headerName: "Externo",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      return params.value === 1 ? "SI" : "NO";
    },
  },
  {
    field: "roles",
    headerName: "Roles",
    flex: 1,
    headerAlign: "left",
    align: "left",
    valueGetter: (params) => {
      return params.value.map((role: Role) => role.name).join(", ");
    },
  },
  {
    field: "activo",
    headerName: "Activo",
    width: 100,
    align: "center",
    renderCell: (params) => {
      return (
        <IconButton
          color={params.row.activo ? "success" : "error"}
          size="large"
          onClick={() => {
            params.row.handleActivo(params.row);
          }}
        >
          {params.row.activo ? <DoneIcon /> : <RemoveIcon />}
        </IconButton>
      );
    },
  },
];
