import { GridColDef } from "@mui/x-data-grid";
import { GridMenuOption } from "../../components/crud/GridMenuOption";
import { Role } from "@interfaces/Role";

export const columnsRoles: GridColDef<Role>[] = [
  GridMenuOption,
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "created_at",
    headerName: "Creado el",
    type: "date",
    flex: 1,
    valueFormatter: (params) => {
      return (
        params.value &&
        new Date(params.value).toLocaleString("es-AR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    },
  },
];
