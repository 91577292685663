import { useEffect, useState } from "react";
import * as Yup from "yup";
import { TextInput, SelectInput } from "stj-components";
import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { UpdatePostulanteConfirmadoInterface } from "../../../../interfaces/PostulanteInterface";
import { useGetGeneros } from "../../../../services/api/generos";
import { useGetOcupaciones } from "../../../../services/api/ocupaciones";
import { useGetTipoDiscapacidades } from "../../../../services/api/tipos_discapacidad";
import { useFormikContext } from "formik";
import { MediaInterface } from "@interfaces/MediaInterface";
import { PerfilPostulanteInterface } from "@interfaces/PerfilPostulanteInterface";
import { FileButton } from "../../../../components/base/FileButton";


export const UpdateValidationSchemaPostulanteConfirmado = Yup.object({
    apellido: Yup.string().required("Se requiere apellido"),
    nombre: Yup.string().required("Se requiere nombre"),
    documento: Yup.number().required("Se requiere número de documento"),
    email: Yup.string()
        .email("Email incorrecto")
        .matches(/@gmail\.com$/i, "Ingrese un email de gmail.com")
        .required("Se requiere un email"),
    fecha_nacimiento: Yup.date()
        .test(
            "fecha_nacimiento",
            "Tiene que ser mayor de 18 años.",
            function (value) {
                if (!value) {
                    return false; // Handles cases where the date is null or undefined
                }
                const fechaNac = new Date(value);
                const today = new Date();
                const age = today.getFullYear() - fechaNac.getFullYear();

                // Check if the birthday has already occurred this year
                const hasBirthdayPassed =
                    today.getMonth() > fechaNac.getMonth() ||
                    (today.getMonth() === fechaNac.getMonth() &&
                        today.getDate() >= fechaNac.getDate());

                const valid = age > 18 || (age === 18 && hasBirthdayPassed);
                return valid;
            }
        )
        .required("Se requiere fecha de nacimiento"),
    nacionalidad: Yup.string().required("Se requiere nacionalidad"),
    genero_id: Yup.number().required("Seleccione género"),
    ocupacion_id: Yup.number().required("Seleccione ocupación"),
    telefono: Yup.string()
        .matches(
            /^[0-9]{6,10}$/,
            "El campo del teléfono debe tener entre 6 y 10 caracteres"
        )
        .required("Se requiere un teléfono"),
    domicilio: Yup.string().required("Se requiere dirección"),
    localidad: Yup.string().required("Se requiere localidad"),
    provincia: Yup.string().required("Se requiere provincia"),

    observaciones: Yup.string().nullable(),
});

export const formInitialValuesPostulanteConfirmado: UpdatePostulanteConfirmadoInterface = {
    id: 0,

    apellido: "",
    nombre: "",
    documento: 0,
    fecha_nacimiento: "",
    nacionalidad: "",
    genero_id: 0,
    ocupacion_id: 0,
    email: "",
    telefono: "",
    domicilio: "",
    localidad: "",
    provincia: "",
    puede_reubicarse: 0,

    media: [],
    tiene_discapacidad: 0,
    necesita_asistencia_al_rendir: 0,

    tiene_antecedentes_penales: 0,
    es_deudor_alimentario: 0,
    perfiles_postulantes: [],

    archivo_dni: "",
    archivo_certificado_discapacidad: "",
    archivo_antecedentes_penales: "",
    archivo_deudor_alimentario: "",

    archivo_titulo_secundario: "",
    archivo_titulo_universitario: "",
    archivo_matricula: "",

    observaciones: "",
}

export const FormPostulanteConfirmado = () => {
    const { data: generos, isError: isErrorGeneros } =
        useGetGeneros();
    const { data: ocupaciones, isError: isErrorOcupaciones } =
        useGetOcupaciones();
    const { data: tiposDiscapacidades, isError: isErrorTiposDiscapacidades } =
        useGetTipoDiscapacidades();

    const { values, setFieldValue, handleChange } =
        useFormikContext<UpdatePostulanteConfirmadoInterface>();

    const [dniFile, setDniFile] = useState<MediaInterface>();
    const [certificadoDiscapacidadFile, setCertificadoDiscapacidadFile] = useState<MediaInterface>();
    const [certificadoAntecedentesPenalesFile, setCertificadoAntecedentesPenalesFile] = useState<MediaInterface>();
    const [certificadoDeudorAlimentarioFile, setCertificadoDeudorAlimentarioFile] = useState<MediaInterface>();

    const [perfilesLaborales, setPerfilesLaborales] = useState<PerfilPostulanteInterface[]>();
    const [tituloSecundarioFile, setTituloSecundarioFile] = useState<MediaInterface>();
    const [tituloUniversitarioFile, setTituloUniversitarioFile] = useState<MediaInterface>();
    const [matriculaFile, setMatriculaFile] = useState<MediaInterface>();


    useEffect(() => {
        console.log({ values });
        // Set files
        setDniFile(values.media.find(archivo => archivo.file_name === `${values.id}_dni.pdf`))
        setCertificadoDiscapacidadFile(values.media.find(archivo => archivo.file_name === `${values.id}_certificado_discapacidad.pdf`))
        setCertificadoAntecedentesPenalesFile(values.media.find(archivo => archivo.file_name === `${values.id}_certificado_antecedentes_penales.pdf`))
        setCertificadoDeudorAlimentarioFile(values.media.find(archivo => archivo.file_name === `${values.id}_certificado_deudor_alimentario.pdf`))

        setPerfilesLaborales(values.perfiles_postulantes);
        values.perfiles_postulantes.forEach((perfilLaboral: PerfilPostulanteInterface) => {
            if (perfilLaboral.perfil.slug === 'perfil_a') {
                setTituloSecundarioFile(perfilLaboral.media[0])
            }
            if (perfilLaboral.perfil.slug === 'perfil_b') {
                setTituloUniversitarioFile(perfilLaboral.media[0])
            }
            if (perfilLaboral.perfil.slug === 'perfil_c') {
                setMatriculaFile(perfilLaboral.media[0])
            }
        })

    }, [values]);

    return (
        <Grid
            style={{ marginTop: '0.5rem' }}
            paddingTop={0}
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={12}>
                <Typography variant="subtitle1" color={"#06283D"} align="center">
                    <strong>DATOS PERSONALES</strong>
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <TextInput
                    label="Apellido"
                    name="apellido"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextInput
                    label="Nombre"
                    name="nombre"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextInput
                    label="Documento"
                    name="documento"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            {dniFile && (
                <Grid item xs={12}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={dniFile.original_url}
                    >
                        <Button variant={"contained"} fullWidth>
                            Ver archivo DNI
                        </Button>
                    </a>
                </Grid>
            )}

            <Grid item xs={12}>
                <FileButton
                    text="Reemplazar Fotocopia DNI"
                    name="archivo_dni"
                    setFieldValue={setFieldValue}
                    accept=".pdf"
                    value={values.archivo_dni}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Fecha de nacimiento"
                    name="fecha_nacimiento"
                    type="date"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Nacionalidad"
                    name="nacionalidad"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                {generos ? (
                    <SelectInput
                        label="Género"
                        options={
                            generos?.map((genero) => ({
                                label: genero.descripcion,
                                value: genero.id,
                            })) ?? []
                        }
                        name="genero_id"
                    />
                ) : isErrorGeneros ? (
                    <div>Error cargando géneros</div>
                ) : (
                    <CircularProgress />
                )}
            </Grid>

            <Grid item xs={3}>
                {ocupaciones ? (
                    <SelectInput
                        label="Ocupación"
                        options={
                            ocupaciones?.map((ocupacion) => ({
                                label: ocupacion.descripcion,
                                value: ocupacion.id,
                            })) ?? []
                        }
                        name="ocupacion_id"
                    />
                ) : isErrorOcupaciones ? (
                    <div>Error cargando ocupaciones</div>
                ) : (
                    <CircularProgress />
                )}
            </Grid>

            <Grid item xs={8}>
                <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextInput
                    label="Teléfono"
                    name="telefono"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle1" color={"#06283D"} align="center">
                    <strong>DOMICILIO</strong>
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    label="Domicilio"
                    name="domicilio"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Localidad"
                    name="localidad"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Provincia"
                    name="provincia"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <SelectInput
                    label="Está disponible para reubicarse en otra ciudad dentro de la provincia"
                    options={[
                        {
                            label: "NO",
                            value: 0
                        },
                        {
                            label: "SI",
                            value: 1
                        },
                    ]}
                    name="puede_reubicarse"
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle1" color={"#06283D"} align="center">
                    <strong>SALUD</strong>
                </Typography>
            </Grid>

            <Grid item xs={Number(values.tiene_discapacidad) === 1 ? 6 : 12}>
                <SelectInput
                    label="Tiene algún tipo de discapacidad"
                    options={[
                        {
                            label: "NO",
                            value: 0
                        },
                        {
                            label: "SI",
                            value: 1
                        },
                    ]}
                    name="tiene_discapacidad"
                />
            </Grid>

            {Number(values.tiene_discapacidad) === 1 && (
                <Grid item xs={6}>
                    {tiposDiscapacidades ? (
                        <SelectInput
                            label="Tipo de discapacidad"
                            options={
                                tiposDiscapacidades?.map((discapacidad) => ({
                                    label: discapacidad.descripcion,
                                    value: discapacidad.id,
                                })) ?? []
                            }
                            name="tipo_discapacidad_id"
                        />
                    ) : isErrorOcupaciones ? (
                        <div>Error cargando tipos de discapacidades</div>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            )}

            {Number(values.tiene_discapacidad) === 1 && certificadoDiscapacidadFile && (
                <Grid item xs={12}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={certificadoDiscapacidadFile.original_url}
                    >
                        <Button variant={"contained"} fullWidth>
                            Ver archivo Certificado Único de Discapacidad
                        </Button>
                    </a>
                </Grid>
            )}

            {Number(values.tiene_discapacidad) === 1 && (
                <Grid item xs={12}>
                    <FileButton
                        text="Reemplazar Certificado de discapacidad"
                        name="archivo_certificado_discapacidad"
                        setFieldValue={setFieldValue}
                        accept=".pdf"
                        required={false}
                        value={values.archivo_certificado_discapacidad}
                    />
                </Grid>
            )}

            <Grid item xs={Number(values.necesita_asistencia_al_rendir) === 1 ? 3 : 12}>
                <SelectInput
                    label="Necesita ayuda para rendir el examen"
                    options={[
                        {
                            label: "NO",
                            value: 0
                        },
                        {
                            label: "SI",
                            value: 1
                        },
                    ]}
                    name="necesita_asistencia_al_rendir"
                />
            </Grid>

            {Number(values.necesita_asistencia_al_rendir) === 1 && (
                <Grid item xs={9}>
                    <TextInput
                        label="Tipo de ayuda necesaria"
                        name="tipo_asistencia_al_rendir"
                        type="text"
                        placeholder=""
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <Typography variant="subtitle1" color={"#06283D"} align="center">
                    <strong>ANTECEDENTES</strong>
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <SelectInput
                    label="Tiene antecedentes penales"
                    options={[
                        {
                            label: "NO",
                            value: 0
                        },
                        {
                            label: "SI",
                            value: 1
                        },
                    ]}
                    name="tiene_antecedentes_penales"
                />
            </Grid>

            {certificadoAntecedentesPenalesFile && (
                <Grid item xs={6}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={certificadoAntecedentesPenalesFile.original_url}
                    >
                        <Button variant={"contained"} fullWidth>
                            Ver archivo Certificado de Antecedentes Penales
                        </Button>
                    </a>
                </Grid>
            )}

            <Grid item xs={12}>
                <FileButton
                    text="Reemplaza certificado de antecedentes penales"
                    name="archivo_antecedentes_penales"
                    setFieldValue={setFieldValue}
                    accept=".pdf"
                    value={values.archivo_antecedentes_penales}
                />
            </Grid>

            <Grid item xs={6}>
                <SelectInput
                    label="Se encuentra en el registro de deudores alimentarios"
                    options={[
                        {
                            label: "NO",
                            value: 0
                        },
                        {
                            label: "SI",
                            value: 1
                        },
                    ]}
                    name="es_deudor_alimentario"
                />
            </Grid>

            {certificadoDeudorAlimentarioFile && (
                <Grid item xs={6}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={certificadoDeudorAlimentarioFile.original_url}
                    >
                        <Button variant={"contained"} fullWidth>
                            Ver archivo Certificado de Deudor Alimentario
                        </Button>
                    </a>
                </Grid>
            )}

            <Grid item xs={12}>
                <FileButton
                    text="Reemplazar certificado de Registro de Deudores Alimentarios Morosos"
                    name="archivo_deudor_alimentario"
                    setFieldValue={setFieldValue}
                    accept=".pdf"
                    value={values.archivo_deudor_alimentario}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle1" color={"#06283D"} align="center">
                    <strong>PERFIL LABORAL</strong>
                </Typography>
                <p>Se postuló para los siguientes perfiles:</p>
            </Grid>

            {perfilesLaborales && (
                <Grid item xs={12} container>
                    {perfilesLaborales.map((perfilPostulante: PerfilPostulanteInterface) => (
                        <Grid key={perfilPostulante.id} item xs={12} container alignItems={'center'} spacing={2}>
                            <Grid item xs={6} mb={2}>
                                <TextField
                                    label="Perfil"
                                    name={perfilPostulante.perfil.label}
                                    value={`${perfilPostulante.perfil.label}: ${perfilPostulante.perfil.descripcion}`}
                                    type="text"
                                    placeholder=""
                                    fullWidth
                                />
                            </Grid>

                            {perfilPostulante.perfil.slug === 'perfil_a' && tituloSecundarioFile && (
                                <>
                                    <Grid item xs={6}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={tituloSecundarioFile.original_url}
                                        >
                                            <Button variant={"contained"} fullWidth>
                                                Ver archivo Título Secundario
                                            </Button>
                                        </a>
                                    </Grid>

                                    <Grid item xs={12} mb={2}>
                                        <FileButton
                                            text="Reemplazar Título secundario"
                                            name="archivo_titulo_secundario"
                                            setFieldValue={setFieldValue}
                                            accept=".pdf"
                                            value={values.archivo_titulo_secundario}
                                        />
                                    </Grid>
                                </>
                            )}

                            {perfilPostulante.perfil.slug === 'perfil_b' && tituloUniversitarioFile && (
                                <>
                                    <Grid item xs={6}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={tituloUniversitarioFile.original_url}
                                        >
                                            <Button variant={"contained"} fullWidth>
                                                Ver archivo Título Grado/Pregrado
                                            </Button>
                                        </a>
                                    </Grid>

                                    <Grid item xs={12} mb={2}>
                                        <FileButton
                                            text="Reemplazar Título universitario o pregrado"
                                            name="archivo_titulo_universitario"
                                            setFieldValue={setFieldValue}
                                            accept=".pdf"
                                            value={values.archivo_titulo_universitario}
                                        />
                                    </Grid>
                                </>
                            )}

                            {perfilPostulante.perfil.slug === 'perfil_c' && matriculaFile && (
                                <>
                                    <Grid item xs={6}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={matriculaFile.original_url}
                                        >
                                            <Button variant={"contained"} fullWidth>
                                                Ver archivo Matrícula
                                            </Button>
                                        </a>
                                    </Grid>

                                    <Grid item xs={12} mb={2}>
                                        <FileButton
                                            text="Reemplazar Matrícula"
                                            name="archivo_matricula"
                                            setFieldValue={setFieldValue}
                                            accept=".pdf"
                                            value={values.archivo_matricula}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
            )}

            <Grid item xs={12}>
                <hr style={{ width: '100%' }} />
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    label="Observaciones"
                    name="observaciones"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    );
}
