import { RoleAddOrUpdateInterface } from "@interfaces/Role";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { useFormikContext } from "formik";
import { SelectInput, TextInput } from "stj-components";

export const FormRole = () => {
  const { setFieldValue, values } =
    useFormikContext<RoleAddOrUpdateInterface>();

  return (
    <Grid container item xs={12} spacing={1} justifyContent="right">
      <Grid item xs={12}>
        <TextInput
          label="Nombre"
          name="name"
          type="text"
          placeholder="Ingrese nombre"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
