import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { formatDateAR } from "../../../../utils/utils"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { ContextualButton } from 'stj-components';

export interface MenuOption {
  action?: (id: any) => void;
  children?: React.ReactNode | string;
  disabled?: boolean;
}

export const columnsConvocatoriaCalendario: GridColDef[] = [
  // GridMenuOption,
  {
    field: 'actions',
    headerName: ' ',
    width: 50,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<boolean>) => {
      const options: MenuOption[] = [
        {
          children: (
            <>
              <OpenInNewIcon />
              Ver
            </>
          ),
          action: () => {
            params.row.handleViewModalToggle(params.row);
          }
        }
      ];

      if (Boolean(params.row.convocatoria.vigente)) {
        options.push({
          children: (
            <>
              <EditIcon />
              Editar
            </>)
          ,
          action: () => {
            params.row.handleEditModalToggle(params.row);
          }
        });

        options.push({
          children: (
            <>
              <DeleteIcon />
              Eliminar
            </>
          ),
          action: () => {
            params.row.handleDeleteModalToggle(params.row);
          }
        });
      }

      return <ContextualButton options={options}></ContextualButton>;
    }
  },
  {
    field: 'fecha_inicio',
    headerName: 'Fecha Inicio',
    type: 'date',
    width: 125,
    valueFormatter: (params) => {

      return formatDateAR(params?.value);
    }
  },
  {
    field: 'actividad',
    headerName: 'Actividad',
    width: 350,
    renderCell: (params: GridRenderCellParams<boolean>) => (
      params.row.actividad.descripcion
    ),
  },
  {
    field: 'fecha_fin',
    headerName: 'Fecha fin',
    type: 'date',
    width: 125,
    valueFormatter: (params) => {
      return formatDateAR(params?.value);
    }
  },
  {
    field: 'observaciones',
    headerName: 'observaciones',
    width: 400,
    align: 'center',
    headerAlign: 'center',
  },
];
