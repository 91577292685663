import { useState } from "react";
import { SimpleCard, SimpleTabs } from "stj-components";
import ListPostulantesSinConfirmar from "./ListPostulantes/ListPostulantesSinConfirmar";
import ListPostulantesConfirmados from "./ListPostulantes/ListPostulantesConfirmados";
import EstadisticasPage from "./Estadisticas/EstadisticasPage";
import IntegracionCanvasPage from "./IntegracionCanvas/IntegracionCanvasPage";
import { useGetConvocatorias } from "../../services/api/convocatorias";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import GestionUsuariosPage from "./GestionUsuarios/GestionUsuariosPage";

const PostulantesPage = () => {
  const [convocatoriaId, setConvocatoriaId] = useState("");
  const [convocatoriaSlug, setConvocatoriaSlug] = useState("");

  const { data: convocatorias } = useGetConvocatorias({});

  const handleChange = (event: SelectChangeEvent) => {
    setConvocatoriaId(event.target.value as string);
    const convocatoriaSelected = convocatorias?.find(
      (convocatoria) => convocatoria.id === Number(event.target.value)
    );
    const convocatoriaSelectedSlug = convocatoriaSelected?.descripcion
      ? convocatoriaSelected?.descripcion.toLowerCase().replaceAll(" ", "_")
      : "";
    setConvocatoriaSlug(convocatoriaSelectedSlug);
  };

  return (
    <SimpleCard title="Gestión de Postulantes">
      <Grid container spacing={1}>
        {/* Comienza select */}
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel id="select-eleccion">Convocatoria</InputLabel>
            <Select
              labelId="select-eleccion"
              id="select-eleccion"
              value={convocatoriaId}
              label="Convocatoria"
              onChange={handleChange}
            >
              {convocatorias?.map((convocatoria, idx) => (
                <MenuItem key={idx} value={String(convocatoria.id)}>
                  {convocatoria.descripcion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Fin select */}

        {convocatoriaId !== "" && (
          <>
            <Grid item xs={12}>
              <SimpleTabs
                tabs={[
                  { label: "Sin confirmar" },
                  { label: "Confirmados" },
                  { label: "Estadísticas" },
                  { label: "Integración con CANVAS" },
                  { label: "Gestión de usuarios" },
                ]}
                panels={[
                  <ListPostulantesSinConfirmar
                    convocatoriaId={convocatoriaId}
                  />,
                  <ListPostulantesConfirmados
                    convocatoriaId={convocatoriaId}
                  />,
                  <EstadisticasPage
                    convocatoriaId={convocatoriaId}
                    convocatoriaSlug={convocatoriaSlug}
                  />,
                  <IntegracionCanvasPage
                    convocatoriaId={convocatoriaId}
                    convocatoriaSlug={convocatoriaSlug}
                  />,
                  <GestionUsuariosPage convocatoriaId={convocatoriaId} />,
                ]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </SimpleCard>
  );
};

export default PostulantesPage;
