import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { usePost } = useReactQuery;

export const useConfirmarMailPostulantePrivate = ({ id }: { id: string }) =>
  usePost<any, any>(pathToUrl(apiRoutes.mailPostulanteConfirmar, { id }));

export const useReenviarEmailConfirmacionPrivate = ({ id }: { id: string }) =>
  usePost<any, any>(pathToUrl(apiRoutes.reenviarEmailConfirmacion, { id }));

export const useEnviarCredencialesCanvas = ({ id }: { id: string }) =>
  usePost<any, any>(pathToUrl(apiRoutes.enviarCredencialesCanvas, { id }));
