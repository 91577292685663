export const baseUrlApi = process.env.REACT_APP_API_URL;

export const apiRoutes = {
  convocatorias: "convocatorias",
  convocatoriasById: "convocatorias/:id",
  enviarTodasLasCredencialesCanvasPerfilA:
    "convocatorias/:id/enviar-credenciales-canvas/perfil-a",
  enviarTodasLasCredencialesCanvasPerfilB:
    "convocatorias/:id/enviar-credenciales-canvas/perfil-b",
  enviarTodasLasCredencialesCanvasPerfilC:
    "convocatorias/:id/enviar-credenciales-canvas/perfil-c",

  actividades: "actividades",
  actividadesById: "actividades/:id",
  actividadesByConvocatoriasById: "convocatorias/:id/actividades",

  // TODO: pasar a plural
  convocatoriaCalendarios: "calendario-convocatoria",
  convocatoriaCalendariosById: "calendario-convocatoria/:id",

  generos: "generos",
  generosById: "generos/:id",

  ocupaciones: "ocupaciones",
  ocupacionesById: "ocupaciones/:id",

  tiposDiscapacidades: "tipos-discapacidades",
  tiposDiscapacidadesById: "tipos-discapacidades/:id",

  postulantes: "postulantes",
  postulantesById: "postulantes/:id",
  mailPostulanteConfirmar: "postulantes/:id/confirmar-email",
  reenviarEmailConfirmacion: "postulantes/:id/reenviar-email-confirmacion",
  enviarCredencialesCanvas: "postulantes/:id/enviar-credenciales-canvas",

  perfiles: "perfiles",

  estadisticas: "estadisticas",
  estadisticasLocalidades: "estadisticas/localidades",
  estadisticasNacionalidades: "estadisticas/nacionalidades",

  canvasIntegration: "canvas-integration",
  canvasIntegrationIndividual: "canvas-integration/individual",
  canvasIntegrationCsvCompleto: "canvas-integration/completo",
  canvasIntegrationListadoAlumnos: "canvas-integration/listado-alumnos",

  users: "users",
  userById: "users/:id",

  roles: "roles",
  rolById: "roles/:id",

  generarUsuarios: "convocatorias/:id/generar-usuarios/:perfil_id",
  toggleActivoUsuarios: "convocatorias/:id/toggle-activo-usuarios/:perfil_id",
  enviarCredencialesHain:
    "convocatorias/:id/enviar-credenciales-hain/:perfil_id",
};
