import { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { createAxios } from "stj-components";
import Cookies from "universal-cookie";

type ErrorType = {
  message?: string;
  error?: string;
};

interface FilePostInterface {
  url: string;
  fileName: string;
  formValues?: any;
}

const cookies = new Cookies();
const token = cookies.get("access_token");

const downloadFile = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  toast.success("Descargando Archivo ...");
};

const postDownloadReporte = (
  filePost: FilePostInterface
): Promise<AxiosResponse<Blob>> => {
  return createAxios.post(filePost.url, filePost.formValues, {
    responseType: "blob",
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  });
};

const getDownloadReporte = (
  filePost: FilePostInterface
): Promise<AxiosResponse<Blob>> => {
  return createAxios.get(filePost.url, {
    responseType: "blob",
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  });
};

//- GENERICO --
export const useFileDownload = (post?: boolean) => {
  const mutation = useMutation<
    AxiosResponse<Blob>,
    AxiosError<ErrorType>,
    FilePostInterface
  >(post ? postDownloadReporte : getDownloadReporte, {
    onSuccess: async (data, variables) => {
      downloadFile(data.data, variables.fileName);
    },
    onError: async (error) => {
      toast.error(`Error ${error?.response?.data?.message}`);
    },
  });
  return mutation;
};
