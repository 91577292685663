import React from "react";
import { appRoutes } from "./appRoutes";
import { ROLE } from "./auth/Role.enum";
import { PERMISSION } from "./auth/Permissions.enum";

import CreateConvocatoriaPage from "./pages/CreateConvocatoriaPage/CreateConvocatoriaPage";
import ListConvocatoriasPage from "./pages/ListConvocatoriasPage/ListConvocatoriasPage";
import DetailConvocatoriaPage from "./pages/DetailConvocatoriaPage/DetailConvocatoriaPage";

import PostulantesPage from "./pages/PostulantesPage/PostulantesPage";
import ListUsersPage from "./pages/UsersPage/ListUsersPage";

export const appComponents: {
  path: string;
  element: React.ReactNode;
  rolesRequired?: ROLE[];
  permsRequired?: PERMISSION[];
}[] = [
  {
    path: appRoutes.crearConvocatorias,
    element: <CreateConvocatoriaPage />,
  },
  {
    path: appRoutes.listarConvocatorias,
    element: <ListConvocatoriasPage />,
  },
  {
    path: appRoutes.detalleConvocatoria,
    element: <DetailConvocatoriaPage />,
  },
  {
    path: appRoutes.listaPostulante,
    element: <PostulantesPage />,
  },
  {
    path: appRoutes.listarUsuarios,
    element: <ListUsersPage />,
  },
];
