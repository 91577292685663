const basePathConvocatorias = "/convocatorias";
const basePathPostulante = "/postulantes";
const basePathUsuario = "/usuarios";

export const appRoutes = {
  /** Rutas convocatorias */
  crearConvocatorias: `${basePathConvocatorias}/crear`,
  listarConvocatorias: `${basePathConvocatorias}/listar`,
  detalleConvocatoria: `${basePathConvocatorias}/detalleConvocatoria/:id`,

  /** Rutas postulantes */
  listaPostulante: `${basePathPostulante}/listar`,

  /** Rutas usuarios */
  listarUsuarios: `${basePathUsuario}/listar`,
};
