import { Modal } from "stj-components";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { PostulanteInterface } from "@interfaces/PostulanteInterface";
import { useEnviarCredencialesCanvas } from "../../../../services/api/mailPostulantes";
import { useState } from "react";
import toast from "react-hot-toast";

interface EnviarCredencialesCanvasModalProps {
    postulante: PostulanteInterface;
    open: boolean;
    setOpen: any;
    refetch: any;
}

export const EnviarCredencialesCanvasModal = ({ postulante, open, setOpen, refetch }: EnviarCredencialesCanvasModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const enviarMutation = useEnviarCredencialesCanvas({ id: postulante.id.toString() });

    const handleEnviarCredencialesCanvas = async (postulante: PostulanteInterface) => {
        setIsLoading(true);
        const data = {
            convocatoria_id: postulante.convocatoria_id,
            postulante_id: postulante.id,
        };
        console.log(postulante);
        try {
            await enviarMutation.mutateAsync(data, {
                onSuccess: () => {
                    toast.success(
                        "Se ha enviado el email con las credenciales. Por favor revise la casilla de correos.",
                    );
                    refetch();
                    setOpen(false);
                }
            });
        } catch (error) {
            toast.error(`Ocurrió un error: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {open && (
                <Modal
                    title=""
                    open={open}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <Grid
                        style={{ marginTop: '0.5rem' }}
                        paddingTop={0}
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Typography align="center">Postulante: <strong>{postulante.apellido} {postulante.nombre}</strong></Typography>
                            <Typography align="center">Usuario: <strong>{postulante.email}</strong></Typography>
                            <Typography align="center">Contraseña: <strong>{postulante.documento}#{postulante.uuid.substring(0, 4)}</strong></Typography>
                            <Typography align="center">¿Enviar email con credenciales de canvas a <strong>{postulante.email}</strong>?</Typography>
                        </Grid>

                        {isLoading
                            ? (<CircularProgress />)
                            : (
                                <Grid item xs={12} container justifyContent={'center'}>
                                    <Button
                                        variant={"contained"}
                                        style={{ margin: 2 }}
                                        onClick={() => setOpen(false)}
                                        disabled={isLoading}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        style={{ margin: 2 }}
                                        onClick={() => handleEnviarCredencialesCanvas(postulante)}
                                        disabled={isLoading}
                                    >
                                        Enviar
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                </Modal>
            )}
        </>
    );
}
