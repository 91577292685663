import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { SimpleCard, SimpleTabs } from "stj-components";
import GenericCrud from "../../components/crud/GenericCrud";
import {
  useGetPaginatedUsers,
  useDeleteUser,
  useUpdateUser,
  useCreateUser,
} from "../../services/api/users";

import { ValidationUser } from "./ValidationUser";
import { apiRoutes } from "../../services/api/apiRoutes";
import { FormUser } from "./FormUser";
import { columnsUsers } from "./columnsUsers";
import { UserInterface } from "@interfaces/UserInterface";
import {
  useCreateRol,
  useDeleteRol,
  useGetAllRoles,
  useUpdateRol,
} from "../../services/api/roles";
import { columnsRoles } from "./columnsRoles";
import { FormRole } from "./FormRole";
import { ValidationRol } from "./ValidationRol";
import { RoleAddOrUpdateInterface } from "@interfaces/Role";
import axios from "axios";
import { pathToUrl } from "../../utils/urlGenerator";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";

const ListUsersPage = () => {
  const cookies = new Cookies();
  const token = cookies.get("access_token");
  // User
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(30);
  const [rowCountState, setRowCountState] = useState(0);
  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      page_size: pageSize,
      include: "roles",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );

  const {
    data: users,
    refetch: refetchUsers,
    isError: isErrorUsers,
  } = useGetPaginatedUsers(queryOptions);

  const {
    data: roles,
    refetch: refetchRoles,
    isError: isErrorRoles,
  } = useGetAllRoles();

  const addUserMutation = useCreateUser();
  const addRolMutation = useCreateRol();
  const deleteMutation = useDeleteUser();
  const deleteRolMutation = useDeleteRol();

  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      users?.total !== undefined ? users?.total : prevRowCountState
    );
  }, [users?.total, setRowCountState]);

  const user = {
    name: "",
    email: "",
    password: "",
    externo: "0",
    activo: "1",
    roles: [],
  };

  const handleActivo = async (item: UserInterface) => {
    const data: any = {
      activo: !item.activo,
    };

    axios
      .put(
        process.env.REACT_APP_API_URL +
          "/" +
          pathToUrl(apiRoutes.userById, {
            id: item?.id,
          }),
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        refetchUsers();
        toast.success("Usuario actualizado correctamente");
      })
      .catch((error) =>
        toast.error(`Ocurrió un error: ${error.response.data.message}`)
      )
      .finally();
  };

  const rolInitialValues: RoleAddOrUpdateInterface = {
    name: "",
  };

  if (users && roles) {
    return (
      <SimpleCard title="Gestionar Usuarios/Roles">
        <SimpleTabs
          tabs={[
            { label: "Users Internos" },
            { label: "Users Externos" },
            { label: "Roles" },
          ]}
          panels={[
            // Usuarios
            <>
              <Grid item xs={12}>
                <GenericCrud
                  data={
                    users.data
                      .filter(
                        (user) => user.externo === 0 || user.externo === null
                      )
                      .map((user: any) => {
                        return {
                          ...user,
                          handleActivo,
                        };
                      }) ?? []
                  }
                  columns={columnsUsers}
                  form={<FormUser />}
                  formValidation={ValidationUser}
                  formInitialValues={user}
                  addMutation={addUserMutation}
                  useEditMutation={useUpdateUser}
                  deleteMutation={deleteMutation}
                  queryToInvalidate={apiRoutes.users}
                  refetch={refetchUsers}
                  page={page}
                  pageSize={pageSize}
                  rowCount={rowCountState}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Grid>
            </>,
            <>
              <Grid item xs={12}>
                <GenericCrud
                  data={
                    users.data
                      .filter((user) => user.externo === 1)
                      .map((user: any) => {
                        return {
                          ...user,
                          handleActivo,
                        };
                      }) ?? []
                  }
                  columns={columnsUsers}
                  form={<FormUser />}
                  formValidation={ValidationUser}
                  formInitialValues={user}
                  addMutation={addUserMutation}
                  useEditMutation={useUpdateUser}
                  deleteMutation={deleteMutation}
                  queryToInvalidate={apiRoutes.users}
                  refetch={refetchUsers}
                  page={page}
                  pageSize={pageSize}
                  rowCount={rowCountState}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Grid>
            </>,
            <>
              <GenericCrud
                data={roles}
                columns={columnsRoles}
                form={<FormRole />}
                formValidation={ValidationRol}
                formInitialValues={rolInitialValues}
                addMutation={addRolMutation}
                useEditMutation={useUpdateRol}
                deleteMutation={deleteRolMutation}
                queryToInvalidate={apiRoutes.roles}
                refetch={refetchRoles}
              />
            </>,
            <Grid></Grid>,
          ]}
        />
      </SimpleCard>
    );
  }

  if (isErrorUsers || isErrorRoles) {
    return <div>Error cargando los datos</div>;
  }

  return <CircularProgress />;
};

export default ListUsersPage;
