import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { ConvocatoriaInterface } from "../../interfaces/ConvocatoriaInterface";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { ModalEditConvocatoria } from "./ModalEditConvocatoria";

interface FieldsEleccionProps {
  convocatoria: ConvocatoriaInterface;
  refresh?: any;
}

const DataConvocatoria = (props: FieldsEleccionProps) => {
  const { convocatoria, refresh } = props;
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const editModalToggle = () => {
    setShowEditModal(!showEditModal);
  }

  return (
    <Grid container item spacing={2} sx={{ pl: 3, pr: 3 }}>

      <Grid item xs={12}>
        <Tooltip title="Editar convocatoria" placement="bottom-end">
          <span style={{ width: "100%" }}>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={editModalToggle}
              disabled={!Boolean(convocatoria.vigente)}
              fullWidth
              style={{ minHeight: "100%" }}
            >
              Editar
            </Button>
          </span>
        </Tooltip>

        <ModalEditConvocatoria
          show={showEditModal}
          handleClose={editModalToggle}
          convocatoria={convocatoria}
          refresh={refresh}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="descripcion"
          label="Nombre de la convocatoria"
          value={convocatoria.descripcion}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="expediente"
          label="Expediente"
          value={convocatoria.expediente ?? ""}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="observaciones"
          label="Observaciones"
          value={convocatoria.observacion ?? ""}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="estado"
          label="Estado"
          value={Boolean(convocatoria.vigente) ? "VIGENTE" : "ARCHIVADA"}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
          style={{ backgroundColor: Boolean(convocatoria.vigente) ? "#82E0AA" : "#F7DC6F" }}
        />
      </Grid>
    </Grid>
  );
};

export default DataConvocatoria;
