import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";
import { Role, RoleAddOrUpdateInterface } from "@interfaces/Role";

const { useFetch, usePost, useUpdate, useDelete } = useReactQuery;

export const useCreateRol = () =>
  usePost<RoleAddOrUpdateInterface, Role>(pathToUrl(apiRoutes.roles));

export const useGetAllRoles = () =>
  useFetch<Role[]>(pathToUrl(apiRoutes.roles));

export const useGetRol = (id: string) =>
  useFetch<Role>(pathToUrl(apiRoutes.rolById, { id }));

export const useUpdateRol = (id: string) =>
  useUpdate<RoleAddOrUpdateInterface, Role>(
    pathToUrl(apiRoutes.rolById, { id })
  );

export const useDeleteRol = () => useDelete<Role>(pathToUrl(apiRoutes.roles));
