import { useEffect, useMemo, useState } from "react";
import {
  useGetPaginatedPostulantes, useUpdatePostulante, useDeletePostulante
} from "../../../services/api/postulantes";
import GenericCrud from "../../../components/crud/GenericCrud";
import { Alert, AlertTitle, CircularProgress, Grid } from "@mui/material";
import {
  columnsPostulantesConfirmados,
} from "./columnsPostulantesConfirmados";
import { GridSortModel } from "@mui/x-data-grid";
import {
  formInitialValuesPostulanteConfirmado,
  FormPostulanteConfirmado,
  UpdateValidationSchemaPostulanteConfirmado
} from "./forms/FormPostulanteConfirmado";
import SearchPostulante from "./SearchPostulante";
import { PostulanteInterface } from "@interfaces/PostulanteInterface";
import { EnviarCredencialesCanvasModal } from "./forms/EnviarCredencialesCanvasModal";
import { apiRoutes } from "../../../services/api/apiRoutes";
import { useFileDownload } from "../../../services/api/canvas-integration";

interface ListPostulantesConfirmadosProps {
  convocatoriaId: string;
}

const ListPostulantesConfirmados = (
  props: ListPostulantesConfirmadosProps
) => {
  const { convocatoriaId } = props;

  const [queryParams, setQueryParams] = useState({});
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sort, setSort] = useState<string>("");

  const [postulanteSelected, setPostulanteSelected] = useState<PostulanteInterface>();
  const [openEnviarEnviarCredencialesCanvasModal, setOpenEnviarEnviarCredencialesCanvasModal] = useState<boolean>(false);

  const fileDownload = useFileDownload(true);

  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      page_size: pageSize,
      sort: sort,
      include: "genero,media,ocupacion,perfiles,perfiles_postulantes.media,perfiles_postulantes.perfil,tipo_discapacidad",
      "filter[convocatoria_id]": convocatoriaId,
      "filter[inscripcionConfirmada]": "true",
      ...queryParams,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, sort, queryParams]
  );

  const handleSort = (sortModel: GridSortModel) => {
    if (sortModel && sortModel.length === 1) {
      const sortDirection: string = sortModel[0].sort === "desc" ? "-" : "";
      const sorteableAttr: string =
        columnsPostulantesConfirmados.filter(
          (col) => col.field === sortModel[0].field
        )[0].sorteableColumnFilter ?? "";
      const querySort: string = `${sortDirection}${sorteableAttr}`;
      setSort(querySort);
    }
  };

  const {
    data: postulantes,
    isLoading: isLoadingPostulantes,
    isError: isErrorPostulantes,
    refetch: refetchPostulantes,
  } = useGetPaginatedPostulantes(queryOptions);

  const handleEnviarCredencialesCanvasModalToggle = (postulante: PostulanteInterface) => {
    // console.log({ accion: 'handleEnviarCredencialesCanvasModalToggle', postulante });
    setPostulanteSelected(postulante);
    setOpenEnviarEnviarCredencialesCanvasModal(true);
  }

  const handleDescargarUsuarioCanvasModalToggle = (postulante: PostulanteInterface) => {
    console.log({ accion: 'handleDescargarUsuarioCanvasModalToggle', postulante });

    const fileName = `user_${postulante.documento}.csv`;

    const formValues = {
      convocatoria_id: convocatoriaId,
      postulante_id: postulante.id,
    };

    fileDownload.mutateAsync({
      fileName: fileName,
      formValues: formValues,
      url: apiRoutes.canvasIntegrationIndividual,
    });
  }

  const deleteMutation = useDeletePostulante();

  const [rowCountState, setRowCountState] = useState(postulantes?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      postulantes?.total !== undefined ? postulantes?.total : prevRowCountState
    );
  }, [postulantes?.total, setRowCountState]);

  const handleOnSubmitFilter = (data: any) => {
    const { nombre, apellido, documento, email, perfilIds } = data;

    const filters = {
      "filter[nombre]": nombre,
      "filter[apellido]": apellido,
      "filter[documento]": documento,
      "filter[email]": email,
      "filter[perfil]": perfilIds?.join(",") ?? "",
    };

    setQueryParams(filters);
  };

  // useEffect(() => { console.log(postulantes) }, [postulantes]);

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            En esta pestaña se muestran los postulantes que ya han confirmado su
            correo electrónico.
            <br />
            Están a la espera de recibir el email para continuar con el examen.
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid container item xs={12} marginBottom={2}>
        <SearchPostulante handleOnSubmit={handleOnSubmitFilter} />
      </Grid>

      {postulantes && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GenericCrud
              data={postulantes.data.map((row) => {
                return {
                  ...row,
                  handleEnviarCredencialesCanvasModalToggle,
                  handleDescargarUsuarioCanvasModalToggle,
                };
              })}
              columns={columnsPostulantesConfirmados}
              viewOnClick={true}
              editOnClick={false}
              form={<FormPostulanteConfirmado />}
              formValidation={UpdateValidationSchemaPostulanteConfirmado}
              formInitialValues={formInitialValuesPostulanteConfirmado}
              hideAddBtn={true}
              addMutation={() => {
                console.log("No se pueden crear postulantes..");
              }}
              useEditMutation={useUpdatePostulante}
              deleteMutation={deleteMutation}
              refetch={refetchPostulantes}
              page={page}
              pageSize={pageSize}
              rowCount={rowCountState}
              setPage={setPage}
              setPageSize={setPageSize}
              handleSort={handleSort}
            />
          </Grid>
        </Grid>
      )}

      {isErrorPostulantes && <div>Error cargando</div>}

      {isLoadingPostulantes && <CircularProgress />}


      {/* Modales de accion */}
      {openEnviarEnviarCredencialesCanvasModal && postulanteSelected && postulanteSelected.id && (
        <EnviarCredencialesCanvasModal
          postulante={postulanteSelected}
          open={openEnviarEnviarCredencialesCanvasModal}
          setOpen={setOpenEnviarEnviarCredencialesCanvasModal}
          refetch={refetchPostulantes}
        />
      )}
    </>
  );
};

export default ListPostulantesConfirmados;
