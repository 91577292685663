import React from "react";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";
import { PostulanteInterface } from "../../../interfaces/PostulanteInterface";
import { formatDateAR } from "../../../utils/utils";
import { MenuOption } from "@components/crud/GridMenuOption";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { ContextualButton } from "stj-components";

interface Sorteable {
  sorteableColumnFilter?: string;
}

interface SorteableGridColDef<T extends GridValidRowModel> extends GridColDef<T>, Sorteable { };

export const columnsPostulantesConfirmados: SorteableGridColDef<PostulanteInterface>[] = [
  {
    field: 'actions',
    headerName: ' ',
    sortable: false,
    filterable: false,
    width: 50,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<boolean>) => {
      const options: MenuOption[] = [
        {
          children: (
            <>
              <OpenInNewIcon />
              Ver
            </>
          ),
          action: () => {
            params.row.handleViewModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <EditIcon />
              Editar
            </>)
          ,
          action: () => {
            params.row.handleEditModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <DownloadIcon />
              Descargar usuario canvas
            </>)
          ,
          action: () => {
            params.row.handleDescargarUsuarioCanvasModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <SendIcon />
              Enviar credenciales canvas
            </>)
          ,
          action: () => {
            params.row.handleEnviarCredencialesCanvasModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <DeleteIcon />
              Eliminar
            </>)
          ,
          action: () => {
            params.row.handleDeleteModalToggle(params.row);
          }
        },
      ];

      return <ContextualButton options={options}></ContextualButton>;
    }
  },
  {
    field: "documento",
    headerName: "DNI",
    sortable: true,
    sorteableColumnFilter: "documento",
    width: 100,
  },
  {
    field: "apellido",
    headerName: "Apellido",
    sortable: true,
    sorteableColumnFilter: "apellido",
    width: 200,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    sortable: true,
    sorteableColumnFilter: "nombre",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    sorteableColumnFilter: "email",
    width: 280,
    valueGetter: (params) => `${params.row?.email}`,
  },
  {
    field: "telefono",
    headerName: "Telefono",
    sortable: false,
    width: 120,
    valueGetter: (params) => `${params.row?.telefono}`,
  },
  {
    field: "localidad",
    headerName: "Localidad",
    sortable: true,
    sorteableColumnFilter: "localidad",
    width: 100,
  },
  {
    field: "domicilio",
    headerName: "Dirección",
    sortable: true,
    sorteableColumnFilter: "domicilio",
    width: 280,
  },
  {
    field: "fecha_nacimiento",
    headerName: "Fecha Nacimiento",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return formatDateAR(params.row.fecha_nacimiento);
    },
  },
  {
    field: "genero",
    headerName: "Género",
    sortable: true,
    sorteableColumnFilter: "genero.descripcion",
    filterable: false,
    width: 150,
    renderCell: (params) => {
      return params.row.genero?.descripcion;
    },
  },
  {
    field: "tipo_discapacidad",
    headerName: "Discapacidad",
    sortable: true,
    sorteableColumnFilter: "tipo_discapacidad.descripcion",
    filterable: false,
    width: 150,
    renderCell: (params) => {
      return (
        <>
          {
            params.row.tiene_discapacidad ?
              (<>SI: {params.row.tipo_discapacidad?.descripcion}</>)
              : (<>NO</>)
          }
        </>
      );
    },
  },
  {
    field: "perfiles",
    headerName: "Perfiles",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return (
        <>
          {params.row.perfiles?.map((perfil: any) => (
            <React.Fragment key={perfil.id}>
              {perfil.label}
              <br />
            </React.Fragment>
          ))}
        </>
      );
    },
  },
  {
    field: "nota",
    headerName: "Nota",
    sortable: true,
    sorteableColumnFilter: "nota",
    width: 50,
  },
  {
    field: "examen",
    headerName: "Examen",
    sortable: false,
    filterable: false,
    width: 150,
    renderCell: (params) => {
      return (
        <>
          {
            params.row.nota
              ? Number(params.row.nota) >= 7
                ? (<>APROBADO</>)
                : (<>DESAPROBADO</>)
              : (<></>)
          }
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Hora de registro",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return new Date(params.row.created_at).toLocaleString();
    },
  },
];

export const columnsPostulanteCSV: GridColDef<PostulanteInterface>[] = [
  {
    field: "uuid",
    headerName: "user_id",
  },
  {
    field: "custom",
    headerName: "integration_id",
    valueGetter: () => undefined,
  },
  {
    field: "email",
    headerName: "login_id",
  },
  {
    field: "custom",
    headerName: "password",
    valueGetter: (params) => `${params.row.documento}#${params.row.uuid.slice(0, 4)}`,
  },
  {
    field: "custom",
    headerName: "ssha_password",
    valueGetter: () => undefined,
  },
  {
    field: "custom",
    headerName: "authentication_provider_id",
    valueGetter: () => undefined,
  },
  {
    field: "nombre",
    headerName: "first_name",
  },
  {
    field: "apellido",
    headerName: "last_name",
  },
  {
    field: "custom",
    headerName: "full_name",
    valueGetter: () => undefined,
  },
  {
    field: "custom",
    headerName: "sortable_name",
    valueGetter: () => undefined,
  },
  {
    field: "nombre",
    headerName: "short_name",
  },
  {
    field: "email",
    headerName: "email",
  },
  {
    field: "custom",
    headerName: "pronouns",
    valueGetter: () => undefined,
  },
  {
    field: "custom",
    headerName: "declared_user_type",
    valueGetter: () => "student",
  },
  {
    field: "custom",
    headerName: "declared_user_type",
    valueGetter: () => "student",
  },
  {
    field: "custom",
    headerName: "canvas_password_notification",
    valueGetter: () => undefined,
  },
  {
    field: "custom",
    headerName: "status",
    valueGetter: () => "active",
  },
];
