import { Autocomplete, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { ComboBox, FormButton } from "stj-components";
import * as Yup from "yup";
import { useGetEstadisticas, useGetLocalidades, useGetNacionalidades } from "../../../services/api/estadisticas";
import { useGetGeneros } from "../../../services/api/generos";
import { useGetTipoDiscapacidades } from "../../../services/api/tipos_discapacidad";
import { useGetOcupaciones } from "../../../services/api/ocupaciones";
import { useGetPerfiles } from "../../../services/api/perfiles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useEffect } from "react";

const initialValues = {
    perfiles: [],
    localidades: [],
    generos: [],
    discapacidades: [],
    ocupaciones: [],
    nacionalidades: [],
};

interface FormEstadisticasProps {
    convocatoriaId: string;
    setLoading: any;
    setEstadisticas: any;
}

export const FormEstadisticas = ({ convocatoriaId, setLoading, setEstadisticas }: FormEstadisticasProps) => {
    const {
        data: perfiles,
        isLoading: isLoadingPerfiles,
        isError: isErrorPerfiles,
    } = useGetPerfiles();

    const {
        data: localidades,
        isLoading: isLoadingLocalidades,
        isError: isErrorLocalidades,
    } = useGetLocalidades();

    const {
        data: generos,
        isLoading: isLoadingGeneros,
        isError: isErrorGeneros,
    } = useGetGeneros();

    const {
        data: tiposDiscapacidades,
        isLoading: isLoadingTiposDiscapacidades,
        isError: isErrorTiposDiscapacidades,
    } = useGetTipoDiscapacidades();

    const {
        data: ocupaciones,
        isLoading: isLoadingOcupaciones,
        isError: isErrorOcupaciones,
    } = useGetOcupaciones();

    const {
        data: nacionalidades,
        isLoading: isLoadingNacionalidades,
        isError: isErrorNacionalidades,
    } = useGetNacionalidades();

    const getEstadisticasMutation = useGetEstadisticas();

    const handleOnSubmit = async (values: any) => {
        setLoading(true);

        try {
            const response = await getEstadisticasMutation.mutateAsync({
                ...values,
                convocatoria_id: convocatoriaId
            }, {
                onSuccess: () => {
                    console.log("SUCCESS");
                    // toast.success(
                    //     "Se ha confirmado el email del postulante.",
                    // );
                    // refetch();
                    // setOpen(false);
                }
            });
            setEstadisticas(response.data);
        } catch (error) {
            console.log("ERROR");
            // toast.error(`Ocurrió un error: ${error}`);
            setEstadisticas({});
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleOnSubmit({
            perfiles: [],
            localidades: [],
            generos: [],
            discapacidades: [],
            ocupaciones: [],
            nacionalidades: [],
        })
    }, []);

    return (
        <Grid container item xs={12}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    perfiles: Yup.array().of(new Yup.StringSchema()).nullable(),
                    localidades: Yup.array().of(new Yup.StringSchema()).nullable(),
                    generos: Yup.array().of(new Yup.StringSchema()).nullable(),
                    ocupaciones: Yup.array().of(new Yup.StringSchema()).nullable(),
                    nacionalidades: Yup.array().of(new Yup.StringSchema()).nullable(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        handleOnSubmit(values as any);
                        setSubmitting(false);
                    }, 500);
                }}
            >
                {(formik) => (
                    <Form style={{ width: "100%" }}>
                        <Grid container item xs={12} columnSpacing={2}>
                            <Grid item xs={12} mb={2}>
                                <Typography variant="h6">Filtros</Typography>
                            </Grid>

                            <Grid item xs={12} md={6} mb={2}>
                                {isLoadingPerfiles && (<CircularProgress />)}
                                {isErrorPerfiles && (<span>Error al cargar perfiles...</span>)}
                                {!isLoadingPerfiles && perfiles && (
                                    <ComboBox
                                        label="Perfiles"
                                        options={perfiles.map(perfil => {
                                            return { label: perfil.label, value: perfil.id }
                                        }) ?? []}
                                        name="perfiles"
                                        multiple
                                        size="small"
                                        optionValue="value"
                                        optionLabel="label"
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} md={6} mb={2}>
                                {isLoadingLocalidades && (<CircularProgress />)}
                                {isErrorLocalidades && (<span>Error al cargar localidades...</span>)}
                                {!isLoadingLocalidades && localidades && (
                                    <Autocomplete
                                        id="localidades"
                                        options={localidades}
                                        getOptionLabel={(option) => option}
                                        multiple
                                        renderInput={(params) => <TextField {...params} label="Localidades" />}
                                        onChange={(event: any, newValue: any | null) => {
                                            formik.setFieldValue('localidades', newValue);
                                        }}
                                        size="small"
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} mb={2}>
                                {isLoadingGeneros && (<CircularProgress />)}
                                {isErrorGeneros && (<span>Error al cargar géneros...</span>)}
                                {!isLoadingGeneros && generos && (
                                    <ComboBox
                                        label="Géneros"
                                        options={generos.map(genero => {
                                            return { label: genero.descripcion, value: genero.id }
                                        }) ?? []}
                                        name="generos"
                                        multiple
                                        size="small"
                                        optionValue="value"
                                        optionLabel="label"
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                {isLoadingTiposDiscapacidades && (<CircularProgress />)}
                                {isErrorTiposDiscapacidades && (<span>Error al cargar tipos de discapacidades...</span>)}
                                {!isLoadingTiposDiscapacidades && tiposDiscapacidades && (
                                    <ComboBox
                                        label="Tipos de discapacidad"
                                        options={tiposDiscapacidades.map(tipoDiscapacidad => {
                                            return { label: tipoDiscapacidad.descripcion, value: tipoDiscapacidad.id }
                                        }) ?? []}
                                        name="discapacidades"
                                        multiple
                                        size="small"
                                        optionValue="value"
                                        optionLabel="label"
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                {isLoadingOcupaciones && (<CircularProgress />)}
                                {isErrorOcupaciones && (<span>Error al cargar ocupaciones...</span>)}
                                {!isLoadingOcupaciones && ocupaciones && (
                                    <ComboBox
                                        label="Ocupaciones"
                                        options={ocupaciones.map(ocupacion => {
                                            return { label: ocupacion.descripcion, value: ocupacion.id }
                                        }) ?? []}
                                        name="ocupaciones"
                                        multiple
                                        size="small"
                                        optionValue="value"
                                        optionLabel="label"
                                    />
                                )}
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                {isLoadingNacionalidades && (<CircularProgress />)}
                                {isErrorNacionalidades && (<span>Error al cargar nacionalidades...</span>)}
                                {!isLoadingNacionalidades && nacionalidades && (
                                    <Autocomplete
                                        id="nacionalidades"
                                        options={nacionalidades}
                                        getOptionLabel={(option) => option}
                                        multiple
                                        renderInput={(params) => <TextField {...params} label="Nacionalidades" />}
                                        onChange={(event: any, newValue: any | null) => {
                                            formik.setFieldValue('nacionalidades', newValue);
                                        }}
                                        size="small"
                                    />
                                )}
                            </Grid>

                            <Grid
                                container
                                item
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12} md={4} alignItems="right">
                                    <FormButton
                                        text="Actualizar"
                                        type="submit"
                                        variant="contained"
                                        startIcon={<SearchOutlinedIcon />}
                                        color="secondary"
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={4}>
                                    <FormButton
                                        text="Limpiar"
                                        onClick={() => {
                                            formik.resetForm();
                                            formik.setFieldValue("perfiles", []);
                                            formik.setFieldValue("localidades", []);
                                            formik.setFieldValue("generos", []);
                                            formik.setFieldValue("ocupaciones", []);
                                            formik.setFieldValue("nacionalidades", []);
                                            handleOnSubmit([]);
                                        }}
                                        variant="outlined"
                                        startIcon={<CleaningServicesIcon />}
                                        color="error"
                                    />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
}
