import { Alert, AlertTitle, Button, CircularProgress, Divider, Grid } from "@mui/material";
import { apiRoutes } from "../../../services/api/apiRoutes";
import { useFileDownload } from "../../../services/api/canvas-integration";
import { useEnviarTodasLasCredencialesCanvasPerfilA } from "../../../services/api/convocatorias";
import { useState } from "react";
import toast from "react-hot-toast";

const SLUG_PERFIL_A = 'perfil_a';
const SLUG_PERFIL_B = 'perfil_b';
const SLUG_PERFIL_C = 'perfil_c';

interface IntegracionCanvasPageProps {
  convocatoriaId: string;
  convocatoriaSlug: string;
}

const IntegracionCanvasPage = (
  props: IntegracionCanvasPageProps
) => {
  const { convocatoriaId, convocatoriaSlug } = props;

  const enviarCredencialesPerfilAMutation = useEnviarTodasLasCredencialesCanvasPerfilA({ id: convocatoriaId.toString() });
  const [enviarIsLoading, setEnviarIsLoading] = useState<boolean>(false);
  const fileDownload = useFileDownload(true);

  const handleDownloadCanvasUsers = () => {
    const fileName = `users_${convocatoriaSlug}.csv`;

    const formValues = {
      convocatoria_id: convocatoriaId,
    };

    fileDownload.mutateAsync({
      fileName: fileName,
      formValues: formValues,
      url: apiRoutes.canvasIntegration,
    });
  }

  const handleDownloadAlumnosPerfil = (perfilSlug: string) => {
    const fileName = `alumnos_${perfilSlug}_${convocatoriaSlug}.csv`;

    const formValues = {
      convocatoria_id: convocatoriaId,
      perfil: perfilSlug,
    };

    fileDownload.mutateAsync({
      fileName: fileName,
      formValues: formValues,
      url: apiRoutes.canvasIntegrationListadoAlumnos,
    });
  }

  const handleSendCanvasUsersPerfilA = async () => {
    console.log("handleSendCanvasUsersPerfilA");
    setEnviarIsLoading(true);
    const data = {};
    try {
      await enviarCredencialesPerfilAMutation.mutateAsync(data, {
        onSuccess: () => {
          toast.success(
            "Se comenzó el envío de credenciales. Este proceso puede demorar",
          );
        }
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    } finally {
      setEnviarIsLoading(false);
    }
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            El siguiente botón permite descargar un csv para registrar todos los postulantes en SIS Import de CANVAS.
          </AlertTitle>
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleDownloadCanvasUsers}
          variant="contained"
          fullWidth
          disabled={fileDownload.isLoading}
        >
          {fileDownload.isLoading
            ? (<CircularProgress />)
            : ("Exportar nuevos usuarios para Canvas")
          }
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            Los siguientes botones permiten descargar csv's para enlistar un grupo de postulantes en un curso en específico de CANVAS.
          </AlertTitle>
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={() => handleDownloadAlumnosPerfil(SLUG_PERFIL_A)}
          variant="contained"
          fullWidth
          disabled={fileDownload.isLoading}
        >
          {fileDownload.isLoading
            ? (<CircularProgress />)
            : ("Alumnos para curso Perfil A")
          }
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => handleDownloadAlumnosPerfil(SLUG_PERFIL_B)}
          variant="contained"
          fullWidth
          disabled={fileDownload.isLoading}
        >
          {fileDownload.isLoading
            ? (<CircularProgress />)
            : ("Alumnos para curso Perfil B")
          }
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => handleDownloadAlumnosPerfil(SLUG_PERFIL_C)}
          variant="contained"
          fullWidth
          disabled={fileDownload.isLoading}
        >
          {fileDownload.isLoading
            ? (<CircularProgress />)
            : ("Alumnos para curso Perfil C")
          }
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            Envío de usuarios y contraseñas de canvas.
          </AlertTitle>
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={() => handleSendCanvasUsersPerfilA()}
          variant="contained"
          fullWidth
          disabled={true}
        >
          {enviarIsLoading
            ? (<CircularProgress />)
            : ("Enviar usuarios y contraseñas para postulantes PERFIL A")
          }
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            En esta sección se cargan las notas obtenidas en los exámenes de canvas.
          </AlertTitle>
        </Alert>
      </Grid>

      <Grid item xs={12}>
        {/* <Button variant="contained" fullWidth>
              Cargar NOTAS
            </Button> */}
      </Grid>
    </Grid >
  );
};

export default IntegracionCanvasPage;
