import { Button, ButtonGroup, CircularProgress, Grid, Typography } from "@mui/material";
import { getColors, getBorderColors } from "../../../utils/utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useState } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

interface ChartsEstadisticasProps {
    loading: boolean;
    estadisticas: any;
}

export const ChartsEstadisticas = ({ loading, estadisticas }: ChartsEstadisticasProps) => {
    // Estados para cambiar tipo de graficos
    const [perfilesChartType, setPerfilesChartType] = useState<'bar' | 'pie'>('bar');
    const [localidadesChartType, setLocalidadesChartType] = useState<'bar' | 'pie'>('bar');
    const [generosChartType, setGenerosChartType] = useState<'bar' | 'pie'>('bar');
    const [ocupacionesChartType, setOcupacionesChartType] = useState<'bar' | 'pie'>('bar');
    const [tiposDiscapacidadesChartType, setTiposDiscapacidadesChartType] = useState<'bar' | 'pie'>('bar');
    const [nacionalidadesChartType, setNacionalidadesChartType] = useState<'bar' | 'pie'>('bar');

    return (
        <Grid item xs={12} container spacing={2} justifyContent={"center"}>
            {loading && (<CircularProgress />)}
            {!loading && estadisticas && (
                <>
                    {/* Gráficos perfiles */}
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Perfiles</Typography>
                        </Grid>

                        {perfilesChartType === 'bar' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Bar
                                    data={{
                                        labels: estadisticas.estadisticasPerfiles.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Perfiles',
                                            data: estadisticas.estadisticasPerfiles.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: 1, offset: 0 }),
                                            borderColor: getBorderColors({ cantidad: 1, offset: 0 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        {perfilesChartType === 'pie' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie
                                    data={{
                                        labels: estadisticas.estadisticasPerfiles.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Perfiles',
                                            data: estadisticas.estadisticasPerfiles.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: estadisticas.estadisticasPerfiles.length, offset: 0 }),
                                            borderColor: getBorderColors({ cantidad: estadisticas.estadisticasPerfiles.length, offset: 0 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} container justifyContent={"center"}>
                            <ButtonGroup>
                                <Button variant={perfilesChartType === 'bar' ? "contained" : "outlined"} onClick={() => setPerfilesChartType('bar')}>Bar Chart</Button>
                                <Button variant={perfilesChartType === 'pie' ? "contained" : "outlined"} onClick={() => setPerfilesChartType('pie')}>Pie Chart</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>


                    {/* Gráficos localidades */}
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Localidades</Typography>
                        </Grid>

                        {localidadesChartType === 'bar' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Bar
                                    data={{
                                        labels: estadisticas.estadisticasLocalidades.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Perfiles',
                                            data: estadisticas.estadisticasLocalidades.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: 1, offset: 1 }),
                                            borderColor: getBorderColors({ cantidad: 1, offset: 1 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        {localidadesChartType === 'pie' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie
                                    data={{
                                        labels: estadisticas.estadisticasLocalidades.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Perfiles',
                                            data: estadisticas.estadisticasLocalidades.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: estadisticas.estadisticasLocalidades.length, offset: 1 }),
                                            borderColor: getBorderColors({ cantidad: estadisticas.estadisticasLocalidades.length, offset: 1 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} container justifyContent={"center"} paddingTop={"auto"}>
                            <ButtonGroup>
                                <Button variant={localidadesChartType === 'bar' ? "contained" : "outlined"} onClick={() => setLocalidadesChartType('bar')}>Bar Chart</Button>
                                <Button variant={localidadesChartType === 'pie' ? "contained" : "outlined"} onClick={() => setLocalidadesChartType('pie')}>Pie Chart</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    {/* Gráficos géneros */}
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Géneros</Typography>
                        </Grid>

                        {generosChartType === 'bar' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Bar
                                    data={{
                                        labels: estadisticas.estadisticasGeneros.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Géneros',
                                            data: estadisticas.estadisticasGeneros.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: 1, offset: 2 }),
                                            borderColor: getBorderColors({ cantidad: 1, offset: 2 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        {generosChartType === 'pie' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie
                                    data={{
                                        labels: estadisticas.estadisticasGeneros.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Géneros',
                                            data: estadisticas.estadisticasGeneros.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: estadisticas.estadisticasGeneros.length, offset: 2 }),
                                            borderColor: getColors({ cantidad: estadisticas.estadisticasGeneros.length, offset: 2 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} container justifyContent={"center"}>
                            <ButtonGroup>
                                <Button variant={generosChartType === 'bar' ? "contained" : "outlined"} onClick={() => setGenerosChartType('bar')}>Bar Chart</Button>
                                <Button variant={generosChartType === 'pie' ? "contained" : "outlined"} onClick={() => setGenerosChartType('pie')}>Pie Chart</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    {/* Gráficos ocupaciones */}
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Ocupaciones</Typography>
                        </Grid>

                        {ocupacionesChartType === 'bar' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Bar
                                    data={{
                                        labels: estadisticas.estadisticasOcupaciones.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Ocupaciones',
                                            data: estadisticas.estadisticasOcupaciones.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: 1, offset: 3 }),
                                            borderColor: getBorderColors({ cantidad: 1, offset: 3 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        {ocupacionesChartType === 'pie' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie
                                    data={{
                                        labels: estadisticas.estadisticasOcupaciones.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Ocupaciones',
                                            data: estadisticas.estadisticasOcupaciones.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: estadisticas.estadisticasOcupaciones.length, offset: 3 }),
                                            borderColor: getBorderColors({ cantidad: estadisticas.estadisticasOcupaciones.length, offset: 3 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} container justifyContent={"center"}>
                            <ButtonGroup>
                                <Button variant={ocupacionesChartType === 'bar' ? "contained" : "outlined"} onClick={() => setOcupacionesChartType('bar')}>Bar Chart</Button>
                                <Button variant={ocupacionesChartType === 'pie' ? "contained" : "outlined"} onClick={() => setOcupacionesChartType('pie')}>Pie Chart</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    {/* Gráficos tipos discapacidades */}
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Tipos de discapacidades</Typography>
                        </Grid>

                        {tiposDiscapacidadesChartType === 'bar' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Bar
                                    data={{
                                        labels: estadisticas.estadisticasTiposDiscapacidades.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Tipos de discapacidades',
                                            data: estadisticas.estadisticasTiposDiscapacidades.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: 1, offset: 4 }),
                                            borderColor: getBorderColors({ cantidad: 1, offset: 4 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        {tiposDiscapacidadesChartType === 'pie' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie
                                    data={{
                                        labels: estadisticas.estadisticasTiposDiscapacidades.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Géneros',
                                            data: estadisticas.estadisticasTiposDiscapacidades.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: estadisticas.estadisticasTiposDiscapacidades.length, offset: 2 }),
                                            borderColor: getColors({ cantidad: estadisticas.estadisticasTiposDiscapacidades.length, offset: 2 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} container justifyContent={"center"}>
                            <ButtonGroup>
                                <Button variant={generosChartType === 'bar' ? "contained" : "outlined"} onClick={() => setTiposDiscapacidadesChartType('bar')}>Bar Chart</Button>
                                <Button variant={generosChartType === 'pie' ? "contained" : "outlined"} onClick={() => setTiposDiscapacidadesChartType('pie')}>Pie Chart</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    {/* Gráficos nacionalidades */}
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Nacionalidades</Typography>
                        </Grid>

                        {nacionalidadesChartType === 'bar' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Bar
                                    data={{
                                        labels: estadisticas.estadisticasNacionalidades.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Nacionalidades',
                                            data: estadisticas.estadisticasNacionalidades.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: 1, offset: 5 }),
                                            borderColor: getBorderColors({ cantidad: 1, offset: 5 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        {nacionalidadesChartType === 'pie' && (
                            <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie
                                    data={{
                                        labels: estadisticas.estadisticasNacionalidades.map((item: any) => item.label),
                                        datasets: [{
                                            label: 'Nacionalidades',
                                            data: estadisticas.estadisticasNacionalidades.map((item: any) => Number(item.cantidad)),
                                            backgroundColor: getColors({ cantidad: estadisticas.estadisticasNacionalidades.length, offset: 4 }),
                                            borderColor: getBorderColors({ cantidad: estadisticas.estadisticasNacionalidades.length, offset: 4 }),
                                            borderWidth: 2,
                                        }]
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} container justifyContent={"center"}>
                            <ButtonGroup>
                                <Button variant={nacionalidadesChartType === 'bar' ? "contained" : "outlined"} onClick={() => setNacionalidadesChartType('bar')}>Bar Chart</Button>
                                <Button variant={nacionalidadesChartType === 'pie' ? "contained" : "outlined"} onClick={() => setNacionalidadesChartType('pie')}>Pie Chart</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>

                    {/* Gráficos inscripciones diarias */}
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Inscripciones diarias</Typography>
                        </Grid>

                        <Grid item xs={12} mb={2} style={{ minHeight: "300px", maxHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Bar
                                data={{
                                    labels: estadisticas.estadisticasInscripcionesDiarias.map((item: any) => item.label),
                                    datasets: [{
                                        label: 'Inscripciones',
                                        data: estadisticas.estadisticasInscripcionesDiarias.map((item: any) => Number(item.cantidad)),
                                        backgroundColor: getColors({ cantidad: 1, offset: 5 }),
                                        borderColor: getBorderColors({ cantidad: 1, offset: 5 }),
                                        borderWidth: 2,
                                    }]
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
