import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { FormButton, SelectInput, TextInput } from "stj-components";

export const FormUser = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleSetPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container item xs={12} spacing={1} justifyContent="right">
      <Grid item xs={12} md={6}>
        <SelectInput
          label="Externo?"
          options={[
            { label: "No", value: 0 },
            { label: "Si", value: 1 },
          ]}
          name="externo"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Nombre"
          name="name"
          type="text"
          placeholder="Ingrese nombre"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Email"
          name="email"
          type="text"
          placeholder="Ingrese email"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Contraseña"
          name="password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSetPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          placeholder="Ingrese contraseña"
          fullWidth
        />
      </Grid>
      {/* <Grid item xs={12}>
        <FormButton
          text={"Guardar"}
          startIcon={<SaveIcon />}
          variant={"contained"}
          type="submit"
        />
      </Grid> */}
    </Grid>
  );
};
