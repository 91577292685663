import { useGetConvocatoriaById } from "../../services/api/convocatorias";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Grid, Link } from "@mui/material";
import { SimpleCard, SimpleTabs } from "stj-components";
import { appRoutes } from "src/appRoutes";
import DataConvocatoria from "./DataConvocatoria";
import TabConvocatoriaCalendario from "./Tab/Calendario/TabConvocatoriaCalendario";


const DetailConvocatoriaPage = () => {
  const { id } = useParams();

  const {
    data,
    isError,
    refetch: refresh,
  } = useGetConvocatoriaById(Number(id));

  if (data) {
    return (
      <>
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" component={RouterLink} to="/">
                Inicio
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to={appRoutes.listarConvocatorias}
              >
                Convocatorias
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <SimpleCard
          title={` ${data?.descripcion} `}
          subtitle={
            data?.expediente ? `Expediente N°: "${data?.expediente}" ` : ""
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DataConvocatoria convocatoria={data} refresh={refresh} />
            </Grid>
            <br />

            <Grid item xs={12}>
              <SimpleTabs
                tabs={[{ label: "Calendario" }]}
                panels={[
                  <TabConvocatoriaCalendario key={data?.id} convocatoria={data} />,
                ]}
                long
              />
            </Grid>
          </Grid>
        </SimpleCard>
      </>
    );
  }

  if (isError) {
    return <div>Error...</div>;
  }

  return <CircularProgress />;
};

export default DetailConvocatoriaPage;
