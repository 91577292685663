import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";
import {
  UserAddOrUpdateInterface,
  UserInterface,
  PaginatedUsersInterface,
} from "../../interfaces/UserInterface";

const { useFetch, useDelete, useUpdate, usePost } = useReactQuery;

export const useGetUsers = (queryParams?: any) =>
  useFetch<UserInterface[]>(pathToUrl(apiRoutes.users), queryParams);

export const useCreateUser = () =>
  usePost<UserAddOrUpdateInterface, UserInterface>(pathToUrl(apiRoutes.users));

export const useGetPaginatedUsers = (queryParams?: any) =>
  useFetch<PaginatedUsersInterface>(
    pathToUrl(apiRoutes.users, {}),
    queryParams
  );

export const useDeleteUser = () => useDelete<any>(pathToUrl(apiRoutes.users));

export const useUpdateUser = (id: string) =>
  useUpdate<any[], any>(pathToUrl(apiRoutes.userById, { id }));
